<template>
  <div
    class="option-box"
    :class="{
      'frosted-background-static': $isSafari,
      'frosted-background': !$isSafari,
    }"
    style="position: relative;"
  >
    <div style="font-size: 20px;margin-bottom: 10px">
      <template v-if="options_error_msg === null">{{ $t('sketchy.page_jeu.options.titre') }}</template>
      <template v-else>-</template>
    </div>

    <div
      v-if="options_error_msg !== null"
      style="position: absolute;top: 5px;left: 0;height: 29px;width: 100%;"
    >
      <span class="tag is-danger">
        {{ $t('sketchy.page_jeu.options.' + options_error_msg) }}
      </span>
    </div>

    <table class="options">
      <thead>
      <tr>
        <th width="50%"></th>
        <th width="50%"></th>
      </tr>
      </thead>
      <tbody class="is-fullwidth">
      <tr>
        <td class="option_libelle"> {{ $t('sketchy.page_jeu.options.drawing_time') }}</td>
        <td class="option">
          <div class="field has-addons">
            <p class="control">
              <input
                type="number"
                step="1"
                min="1"
                max="300"
                class="input is-small"
                v-model="gameOptions.drawing_time"
                :readonly="!isHost"
                :style="{
                  'background-color': isHost ? undefined : '#f3f3f3',
                  'color': isHost ? undefined : '#999',
                  'cursor': isHost ? undefined : 'default',
                }"
                @blur="checkDrawingTimeOverflow"
                @keyup.enter="checkDrawingTimeOverflow"
              />
            </p>
            <p class="control">
              <a class="button is-static is-small">
                {{ $t('sketchy.page_jeu.options.seconds') }}
              </a>
            </p>
          </div>
        </td>
      </tr>

      <tr>
        <td class="option_libelle">
          <span
            class="has-tooltip-arrow"
            :data-tooltip="$t('sketchy.page_jeu.options._tooltips.player_limit')"
          >
            {{ $t('sketchy.page_jeu.options.player_limit') }}
          </span>
        </td>
        <td class="option">
          <div class="field has-addons">
            <p class="control">
              <input
                type="number"
                step="1"
                min="1"
                class="input is-small"
                v-model="gameOptions.player_limit"
                :readonly="!isHost"
                :style="{
                  'background-color': isHost ? undefined : '#f3f3f3',
                  'color': isHost ? undefined : '#999',
                  'cursor': isHost ? undefined : 'default',
                }"
                @blur="checkPlayerLimitOverflow"
                @keyup.enter="checkPlayerLimitOverflow"
              />
            </p>
            <p class="control">
              <a class="button is-static is-small">
                {{ $t('sketchy.page_jeu.options.players') }}
              </a>
            </p>
          </div>
        </td>
      </tr>

      <tr>
        <td class="option_libelle">
          <span
            class="has-tooltip-arrow"
            :data-tooltip="$t('sketchy.page_jeu.options._tooltips.scores')"
          >
            {{ $t('sketchy.page_jeu.options.scores') }} <span style="font-size: 13px;color: #aaa;margin-left: 3px;">(beta)</span>
          </span>
        </td>
        <td class="option">
          <Checkbox v-model="gameOptions.scores" :disabled="!isHost"/>
        </td>
      </tr>

      <tr>
        <td class="option_libelle">
          <span
            class="has-tooltip-arrow"
            :data-tooltip="$t('sketchy.page_jeu.options._tooltips.custom_words')"
          >
            {{ $t('sketchy.page_jeu.options.custom_words') }}
          </span>
        </td>
        <td class="option">
          <Checkbox v-model="gameOptions.custom_words" :disabled="!isHost"/>
        </td>
      </tr>

      <tr>
        <td class="option_libelle">
          <span
            class="has-tooltip-arrow"
            :data-tooltip="$t('sketchy.page_jeu.options._tooltips.longer_games')"
          >
            {{ $t('sketchy.page_jeu.options.longer_games') }}
          </span>
        </td>
        <td class="option">
          <Checkbox
            v-model="gameOptions.longer_games"
            :disabled="!isHost || nb_joueurs < 3 || nb_joueurs >= 6"
          />
        </td>
      </tr>

      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'E0GameOptions',

  data() {
    return {
      lastValidDrawinTime: 45,
      lastValidPlayerLimit: 50,

      gameOptions: {
        drawing_time: 45,
        player_limit: 50,
        custom_words: true,
        longer_games: false,
        scores: false,
      },
    };
  },

  methods: {
    onMessage(msg_type, msg_data) {
      if (msg_type === 'init_game_options' || msg_type === 'game_options') {
        console.info('réception options', msg_data);
        for (let k in msg_data)
          if (k in this.gameOptions) {
            if (k === 'drawing_time' && this.gameOptions.drawing_time === '') continue;
            if (k === 'player_limit' && this.gameOptions.player_limit === '') continue;

            this.$set(this.gameOptions, k, msg_data[k]);
            console.info('réception options', k, msg_data[k]);
          }
      }
      console.info(this.gameOptions);
    },

    checkDrawingTimeOverflow() {
      if (this.isHost) {
        if (this.gameOptions.drawing_time > 300)
          this.gameOptions.drawing_time = 300;

        if (this.gameOptions.drawing_time <= 0)
          this.gameOptions.drawing_time = 45;
      }
    },
    checkPlayerLimitOverflow() {
      if (this.isHost) {
        if (this.gameOptions.player_limit > 100)
          this.gameOptions.player_limit = 100;

        if (this.gameOptions.player_limit <= 0)
          this.gameOptions.player_limit = 50;
      }
    },
  },

  computed: {
    options_error_msg() {
      if (!this.checkDrawingTime) return 'invalid_drawing_time';
      if (!this.checkPlayerLimit) return 'invalid_player_limit';
      return null;
    },

    checkDrawingTime() {
      return (Number.isInteger(parseInt(this.gameOptions.drawing_time, 10)) && parseInt(this.gameOptions.drawing_time, 10) > 0);
    },
    checkPlayerLimit() {
      return (Number.isInteger(parseInt(this.gameOptions.player_limit, 10)) && parseInt(this.gameOptions.player_limit, 10) > 0);
    },
  },

  watch: {
    'gameOptions': {
      handler: function () {
        if (this.isHost) {
          let options = {...this.gameOptions};

          if (this.gameOptions.drawing_time === '') {
            options.drawing_time = this.lastValidDrawinTime;
          } else {
            options.drawing_time = parseInt(this.gameOptions.drawing_time);

            if (options.drawing_time)
              this.lastValidDrawinTime = options.drawing_time;
            else
              options.drawing_time = this.lastValidDrawinTime;
          }

          if (this.gameOptions.player_limit === '') {
            options.player_limit = this.player_limit;
          } else {
            options.player_limit = parseInt(this.gameOptions.player_limit);

            if (options.player_limit)
              this.player_limit = options.player_limit;
            else
              options.player_limit = this.player_limit;
          }

          this.$emit('sendMsg', 'modify_options', options);
        }
      },
      deep: true,
    },

    'nb_joueurs': {
      handler: function(nb_joueurs, old) {
        if (nb_joueurs === 3) {
          this.gameOptions.longer_games = true;
        }
        else if (nb_joueurs <= 2) {
          this.gameOptions.longer_games = false;
        }
        else if (old === 3 && nb_joueurs === 4 && this.gameOptions.longer_games) {
          this.gameOptions.longer_games = false;
        } else if (nb_joueurs >= 6) {
          this.gameOptions.longer_games = false;
        }
      },
    },
  },

  components: {
    Checkbox: () => import('../global/form/Checkbox'),
  },

  props: ['isHost', 'nb_joueurs', 'salon_langue'],
}
</script>

<style lang="scss" scoped>
.option-box {
  font-family: cabin_sketch_regular;
  margin-top: 16px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  width: 300px;
  //height: 200px;

  .options {
    $margin: 10px;
    margin: $margin;
    width: calc(100% - #{$margin*2});

    font-size: 17px;
    line-height: 40px;

    .option_libelle {
      text-align: left;
      line-height: 31px;
    }

    .option {
      text-align: right;
    }
  }

  .cercle-info {
    display: inline-block;

    $size: 15px;
    width: $size;
    height: $size;
    border-radius: $size;
    background-color: rgba(0, 0, 0, 0.35);

    text-align: center;
    line-height: $size + 2;
    margin-left: 3px;
    font-size: $size - 2;
    vertical-align: middle;
    color: white;
  }
}
</style>
